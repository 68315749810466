import React, { useEffect, useState } from "react";
import { toDate } from "../helpers/helpers";

export const EventsList = () => {

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function reloadEvents() {
    setLoading(true);
    fetch(`/api/events?planned=true`)
      .then(res => {
       setLoading(false);
        if (res.ok) {
          return res.json();
        }
        throw res.statusText
      }, (error) => {
        setLoading(false);
        setError(error.message);
      })
      .then(
        (result) => {
          setEvents(result ? result : [])
        },
        (error) => {
          setError(error)
        })
  }

  useEffect(() => {
    reloadEvents()
  }, [])

  return <>
            <div className="mb-4">
              <h2 className="title is-4">Veranstaltungen</h2>

              {events.length === 0 && !loading && (
                <div className="box has-background-light">
                  <h3 className="title is-6">Aktuell sind keine Veranstaltungen geplant.</h3>
                  <p>
                      Melde dich für unseren Newsletter an und verpasse kein Event.
                  </p>
                </div>
              )}

              {events.sort((event1, event2) => new Date(event1.date) - new Date(event2.date)).map((event) => (
                <div key={event.title} className="box has-background-light">
                  <a href={`/events/?id=${event.id}`}>
                    <div className="is-6 mb-1"><span className="tag is-dark mr-3">{toDate(event.date)}</span> {event.title}</div>
                    <div className="mb-0 mt-2 has-text-dark is-size-6">
                      <b>Treffpunkt:</b> {event.location}
                    </div>

                    <div className="has-text-dark mt-2">
                      {event.teaser}
                    </div>
                  </a>
                </div>

              ))}

            </div>
  </>
}