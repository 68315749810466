import React, { useEffect, useState } from "react";
import { RainbowMenu } from "../components/RainbowMenu";
import { Sidebar } from "../components/Sidebar";
import { EventsList } from "../components/EventsList";
import { Event } from "../components/Event";
import { LogOpen } from "../components/LogOpen";
import { Helmet } from "react-helmet";

import "./styles.scss"

export default function EventsPage({ location }) {

  const [eventId, setEventId] = useState(undefined)

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEventId(params.get('id'));
  })

  return <div>
    <Helmet>
      <title>Veranstaltungen</title>
    </Helmet>
    <LogOpen page="event" />
    <RainbowMenu active="Willkommen" relativePath="../"></RainbowMenu>

    <div className="columns">
      <div className="column is-3">
        <Sidebar></Sidebar>
      </div>
      <div className="column is-9">
        {eventId === null && <>
          <EventsList path="/" />
        </>}
        {eventId !== null && eventId !== undefined && <>
          <Event eventId={eventId} />
        </>}
      </div>

    </div>

  </div>;
}
